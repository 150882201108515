<template>
  <div>
    <div class="d-flex justify-content-center" v-if="parentIsLoading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <div class="overflow-hidden">
        <CRow>
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              {{ $t("role.orders.rounds.ddmp.detail.title") }}
            </div>
            <div class="ui-type-display-sm">{{ producerName(producer) }}</div>
            <div class="ui-type-body mb-2">
              <OrdersLocationMap
                :title="producerPositionName(producer)"
                :position="producerPosition(producer)"
              />
              <OrdersContacts
                class="ml-3"
                :title="$t('role.orders.contacts.title')"
                :contacts="producerContacts(producer)"
              />
            </div>
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto" v-if="producer.ddmp.delivery_type == 3">
            <font-awesome-icon icon="truck" />
            {{ $t("role.orders.rounds.ddmp.detail.directDelivery") }}
            <strong>
              {{ producerDeliveryDate(producer, "dd-MM") }}
              {{ $t("role.orders.rounds.ddmp.at") }}
              {{ producerDeliveryDate(producer, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto" v-else>
            <font-awesome-icon icon="warehouse" />
            {{ $t("role.orders.rounds.ddmp.detail.wh") }}
            <strong>"{{ producerWarehouseName(producer) }}"</strong>
            {{ $t("role.orders.rounds.ddmp.from") }}
            <strong>
              {{ producerDeliveryDate(producer, "dd-MM") }}
              {{ $t("role.orders.rounds.ddmp.to") }}
              {{ producerDeliveryDate(producer, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto" v-if="producer.ddmp.delivery_type != 3">
            <CButton
              color="primary"
              @click.prevent="allDelivered"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{
                $t("role.orders.rounds.ddmp.detail.allCollectedBtn")
              }}</CButton
            >
          </CCol>
        </CRow>

        <CAlert
          color="warning"
          v-if="producerOrdersNotWeighed(producer) > 0"
          class="mb-3"
          >{{
            $t("role.orders.rounds.ddmp.detail.notWeighed", [
              producerOrdersNotWeighed(producer),
            ])
          }}</CAlert
        >
      </div>

      <CCard class="mb-2">
        <CCardBody>
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('products') }"
                @click.prevent="setView('products')"
              >
                {{ $t("role.orders.rounds.ddmp.detail.tabs.products.label") }}
              </div>
            </li>
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('orders') }"
                @click.prevent="setView('orders')"
              >
                {{ $t("role.orders.rounds.ddmp.detail.tabs.orders.label") }}
              </div>
            </li>
          </ul>
          <div>
            <ProductsTable :producer="producer" v-if="isView('products')" />
            <OrdersTable :producer="producer" v-if="isView('orders')" />
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import ProductsTable from "./ProductsTable";
import OrdersTable from "./OrdersTable";
import OrdersContacts from "../../../share/OrdersContacts";
import OrdersLocationMap from "../../../share/OrdersLocationMap";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "Detail",

  components: {
    ProductsTable,
    OrdersTable,
    OrdersContacts,
    OrdersLocationMap,
  },

  mixins: [orderManagementMixin],

  props: {
    producer: { type: Object, required: true },
    parentIsLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      PERMS,
      view: "products",
    };
  },

  mounted() {},

  methods: {
    setView(view) {
      this.view = view;
    },

    isView(view) {
      return this.view === view;
    },

    allDelivered() {
      const ddmps = [this.producer.id];
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("ddmp.statusCode.next"),
          ddmps,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
